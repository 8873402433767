import View from '@/view';
import * as ConstantRouteName from "@/constants/route-name";
import loadable from "@/utils/loadable";

const routes = [
  {
    path: '/',  
    component: View,
    redirect: {
      name: ConstantRouteName.HOME,
    },
  },
  {
    path: '/home',
    name: ConstantRouteName.HOME,
    component: loadable(() => import(/* webpackChunkName: "home" */ '@/pages/home')),
  }
];

export default routes;
